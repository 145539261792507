import './App.css';
import InterviewPrepForm from './components/InterviewPrepForm';

function App() {
  return (
    <div className="App">
      <InterviewPrepForm />
    </div>
  );
}

export default App;
