import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import * as pdfjsLib from 'pdfjs-dist';
import mammoth from 'mammoth';
import './InterviewPrepForm.css'; // Custom CSS for design
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';


// Set up the PDF.js worker source by pointing to the worker file in the public folder
pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

// Use the environment variable for the backend URL
const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
//console.log('Backend URL:', backendUrl);

  // Format response text (for headings and paragraphs)
  const formatResponse = (text) => {
    return (
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]} 
        components={{
          a: ({ node, ...props }) => (
            <a {...props} target="_blank" rel="noopener noreferrer">
              {props.children}
            </a>
          ),
        }}
      >
        {text}
      </ReactMarkdown>
    );
  };

//Add reuasble InputField component
const InputField = ({ label, value, onChange, placeholder, isTextarea, rows = 3 }) => (
  <div className="input-container">
    <label>{label}:</label>
    {isTextarea ? (
      <textarea
        className="textarea-field"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
      />
    ) : (
      <input
        className="input-field"
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    )}
  </div>
);

// Function to convert PDF, DOCX, and TXT files to text
const readFileAsText = async (file) => {
  const fileExtension = file.name.split('.').pop().toLowerCase();

  if (fileExtension === 'pdf') {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async function () {
        const typedArray = new Uint8Array(reader.result);
        try {
          const pdf = await pdfjsLib.getDocument(typedArray).promise;
          let text = '';
          for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const content = await page.getTextContent();
            const strings = content.items.map((item) => item.str);
            text += strings.join(' ');
          }
          resolve(text);
        } catch (error) {
          reject(error);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  } else if (fileExtension === 'docx') {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async function (e) {
        try {
          const arrayBuffer = e.target.result;
          const result = await mammoth.extractRawText({ arrayBuffer });
          resolve(result.value);
        } catch (error) {
          reject(error);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  } else if (fileExtension === 'txt') {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = function (e) {
        resolve(e.target.result);
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  } else {
    throw new Error('Unsupported file format. Please upload PDF, DOCX, or TXT.');
  }
};

function InterviewPrepForm() {
  const [companyName, setCompanyName] = useState('');
  const [industry, setIndustry] = useState('');
  const [interviewer, setInterviewer] = useState('');
  const [resumeText, setResumeText] = useState('');
  const [jobDescText, setJobDescText] = useState('');
  const [selection, setSelection] = useState('');
  const [customOption, setCustomOption] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1); 
  const [errorMessage, setErrorMessage] = useState('');
  const [fileName, setFileName] = useState({ resume: '', jobDesc: '' });

  // Dropzone setup for Resume
  const { getRootProps: getResumeProps, getInputProps: getResumeInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleFileUpload(acceptedFiles[0], setResumeText, 'resume'),
  });

  // Dropzone setup for Job Description
  const { getRootProps: getJobDescProps, getInputProps: getJobDescInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleFileUpload(acceptedFiles[0], setJobDescText, 'jobDesc'),
  });

  // Validation for Step 1
  const validateStep1 = () => {
    if (!companyName || !industry || !resumeText || !jobDescText || !interviewer) {
      setErrorMessage('All fields are required. Put NA if needed.');
      return false;
    }
    return true;
  };

  // Validation for Step 2
  const validateStep2 = () => {
    if (selection === 'Other' && !customOption.trim()) {
      setErrorMessage('Please specify a custom option if "Other" is selected.');
      return false;
    }
    return true;
  };

  // Proceed to Step 2 if Step 1 validation passes
  const handleNextStep = () => {
    if (validateStep1()) {
      setErrorMessage('');
      setStep(2); // Move to step 2
    }
  };

  // Go back to Step 1
  const handleBackStep = () => {
    setStep(1); // Move back to step 1
  };

  // Handle text area change, overriding file content
  const handleTextChange = (text, setText, fileType) => {
    setErrorMessage(''); // Reset the error message
    if (fileName[fileType]) {
      if (window.confirm('Typing will erase the uploaded file. Do you want to continue?')) {
        setFileName({ ...fileName, [fileType]: '' }); // Clear the file name
        setText(text);
      }
    } else {
      setText(text);
    }
  };

  // Handle file upload and convert it to text
  const handleFileUpload = async (file, setText, fileType) => {
    try {
      setErrorMessage(''); // Reset the error message
      const plainText = await readFileAsText(file);
      setText(plainText);
      setFileName({ ...fileName, [fileType]: file.name }); // Store file name
    } catch (error) {
      setErrorMessage(error.message); // Display error for unsupported files
    }
  };

  // Clear all fields in Step 1
  const handleClearAll = () => {
    setCompanyName('');
    setIndustry('');
    setInterviewer('');
    setResumeText('');
    setJobDescText('');
    setErrorMessage('');
    setFileName({ resume: '', jobDesc: '' });
  };

  // Fetch the GPT-4 response (called when Step 3 shows loading spinner)
  const fetchGptResponse = async (formData) => {
    setLoading(true); // Set loading state

    try {
      const response = await axios.post(`${backendUrl}/api/submit`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      

      setResponse(response.data.gptResponse); // Update response state to show GPT-4 response
      setStep(4); // Move to step 4 to display the response

    } catch (error) {
      setErrorMessage('Error submitting the form. Please try again.');
      console.error('Error submitting form data:', error);
      setStep(2); // Return to Step 2 in case of error
    } finally {
      setLoading(false); // Stop loading after response
    }
  };

  // Handle form submission (go to Step 3 and show spinner)
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateStep2()) {
      return;
    }

    const formData = {
      companyName,
      industry,
      interviewer,
      resumeText,
      jobDescText,
      selection,
      customOption: selection === 'Other' ? customOption : null,
    };

    setStep(3); // Move to Step 3 to show spinner while fetching response
    fetchGptResponse(formData); // Call the GPT-4 response after spinner is shown
  };

  // Handle choosing another option (return to Step 2)
  const handleChooseAnotherOption = () => {
    setStep(2); // Go back to Step 2 to choose another option
  };

  // Handle starting over (reset all fields and go to Step 1)
  const handleStartOver = () => {
    setStep(1);
    handleClearAll(); // Reset all input fields
    setResponse(''); // Clear the response
  };

  return (
    <div className="interview-prep-container">
      <div className="header-image">
        <img src={`${process.env.PUBLIC_URL}/banner.png`} alt="Logo" className="top-logo" />
      </div>
      <h2 className="form-heading">Job Interview Preparation Assistant</h2>

      {/* Step 1: Collect Basic Information */}
      {step === 1 && (
        <div>
          <div className="formatted-response">
            
          </div>
          <div className="input-container">
            <InputField
              label="Company Name"
              value={companyName}
              onChange={(e) => handleTextChange(e.target.value, setCompanyName)}
              placeholder="Enter Company Name"
            />

          </div>
          <div className="input-container">
          <InputField
              label="Industry"
              value={industry}
              onChange={(e) => handleTextChange(e.target.value, setIndustry)}
              placeholder="Enter Industry"
            />

          </div>
          <div className="input-container">
            <label>Who is your next interview with?:</label>
            <select
              className="input-field"
              value={interviewer}
              onChange={(e) => handleTextChange(e.target.value, setInterviewer)}
              required
            >
              <option value="">Select</option>
              <option value="Recruiter">Recruiter</option>
              <option value="Hiring Manager">Hiring Manager</option>
              <option value="Peer in Same Department">Peer in Same Department</option>
              <option value="Peer in Different Department">Peer in Different Department</option>
              <option value="Someone who will Report to you">Someone who will Report to you</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {/* Resume Input Option */}
          <div className="input-container">
            
            <div className="input-field-group">
            <label>Resume:</label>
              <textarea
                className="textarea-field"
                value={resumeText}
                onChange={(e) => handleTextChange(e.target.value, setResumeText, 'resume')}
                placeholder="Enter your resume or upload a file"
                rows="3"
              />
              <div {...getResumeProps()} className="browse-button">
                <input {...getResumeInputProps()} />
                <p>{fileName.resume ? fileName.resume : 'Browse'}</p>
              </div>
            </div>
          </div>

          {/* Job Description Input Option */}
          <div className="input-container">
            <label>Job Description:</label>
            <div className="input-field-group">
              <textarea
                className="textarea-field"
                value={jobDescText}
                onChange={(e) => handleTextChange(e.target.value, setJobDescText, 'jobDesc')}
                placeholder="Enter job description or upload a file"
                rows="3"
              />
              <div {...getJobDescProps()} className="browse-button">
                <input {...getJobDescInputProps()} />
                <p>{fileName.jobDesc ? fileName.jobDesc : 'Browse'}</p>
              </div>
            </div>
          </div>

          {errorMessage && <p className="error-message">{errorMessage}</p>}

          <button className="clear-button" type="button" onClick={handleClearAll}>Clear All</button>
          <button className="next-button" type="button" onClick={handleNextStep}>Next</button>
        </div>
      )}

      {/* Step 2: Show Options After Clicking "Next" */}
      {step === 2 && (
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <label>Select an Option:</label>
            <select
              className="input-field"
              value={selection}
              onChange={(e) => handleTextChange(e.target.value, setSelection)}
              required
            >
              <option value="">Select</option>
              <option value="Understanding the Company">Understanding the Company</option>
              <option value="Summarizing Recent News">Summarizing Recent News</option>
              <option value="Help me Improve my Resume for this Job">Help me Improve my Resume for this Job</option>
              <option value="Generating Interview Questions with Answers">Generating Interview Questions with Answers</option>
              <option value="Questions for Me to Ask">Questions for Me to Ask</option>
              <option value="Interview Prep">Interview Prep</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {selection === 'Other' && (
            <div className="input-container">
              <label>Please specify:</label>
              <textarea
                className="textarea-field"
                value={customOption}
                onChange={(e) => handleTextChange(e.target.value, setCustomOption)}
                placeholder="Enter your request..."
                rows="4"
                required={selection === 'Other'}
              />
            </div>
          )}

          {errorMessage && <p className="error-message">{errorMessage}</p>}

          <button className="back-button" type="button" onClick={handleBackStep}>Back</button>
          <button className="submit-button" type="submit" disabled={loading}>
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      )}

      {/* Step 3: Show spinner while waiting for GPT-4 response */}
      {step === 3 && (
        <div className="loading-container">
          <div className="spinner"></div>
          <p className="loading-text">Fetching Response...</p>
        </div>
      )}

      {/* Step 4: Display response */}
      {step === 4 && (
        <div className="response-container">
          <h3 className="response-heading">Response to: {selection}</h3>
          <div className="formatted-response">
            {formatResponse(response)}
          </div>

          <div className="button-group">
            <button className="choose-another-option-button" type="button" onClick={handleChooseAnotherOption}>Choose Another Option</button>
            <button className="start-over-button" type="button" onClick={handleStartOver}>Start Over</button>
          </div>
        </div>
      )}

      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}

export default InterviewPrepForm;
